<template>
  <section v-if="!countLoading">
    <template v-if="!isEmpty">
      <v-container class="inner-container">
        <h1 v-if="title" class="dynamic-sub-title mb-10">{{ title }}</h1>
        <v-row>
          <v-col v-for="item in list" :key="item.id" cols="12" lg="3" md="6">
            <Item :item="item" />
          </v-col>
        </v-row>
      </v-container>
      <template v-if="has_next">
        <div class="d-flex justify-center mt-5">
          <Main-btn @click="more">{{ $t("action.look-more") }}</Main-btn>
        </div>
      </template>
    </template>

    <div
      v-if="showEmptyBlock"
      style="min-height: 400px"
      class="d-flex flex-column justify-center align-center"
    >
      <h2 class="text-3 text-center pa-5" style="color: #00304f">
        {{ dataEmptyText }}
      </h2>
      <Main-btn v-if="showBackBtn" :to="{ name: 'cloud-index' }">{{
        actionBackText
      }}</Main-btn>
    </div>
  </section>
</template>

<script>
import indexList from "@/components/page/indexList.js";
export default {
  mixins: [indexList],
  props: {
    title: String,
    type: String,
  },
  components: {
    Item: () => import("@/components/cloud/item.vue"),
  },
  computed: {
    list() {
      if (!this.indexData) return [];
      return this.indexData.data;
    },
    indexApi() {
      return this.$api.cloud.public.index;
    },
    countApi() {
      return this.$api.cloud.public.count;
    },
    paramsTimeKey() {
      return "start_at";
    },
    otherParams() {
      let params = {
        per: 8,
      };
      if (!this.hasFilter) {
        params.is_end = this.type === "past" ? 1 : 0;
      }
      if (this.type == "future" || this.hasFilter)
        params.sort = { start_at: "asc" };
      return params;
    },
  },
};
</script>
<style lang="sass" type="text/sass" scoped>
.filterResults-index-bg
  background-image: url('~@/assets/img/bg/filterResultBg.png')
  background-position: center top
</style>