<template>
  <div>
    <Main-top
      v-if="!categoryLoading"
      class="cloud-main-top"
      :title="$t('page.cloud')"
      :filterMode="true"
      :categoryData="categoryData"
      :routeCategory="routeCategory"
      :routeTime="routeTime"
    ></Main-top>
    <div class="filterResults-index-bg">
      <EventBlock v-if="hasFilter" />
      <template v-else>
        <EventBlock
          :title="$t('cloud.future-event')"
          :type="'future'"
          class="mb-10"
        />
        <EventBlock :title="$t('cloud.past-event')" :type="'past'" />
      </template>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import indexMixins from "@/components/page/index.js"
import EventBlock from '@/components/cloud/eventBlock.vue'
export default {
  mixins: [indexMixins],
  components: {
    EventBlock,
  },
  data: ()=>({
    count: 0,
  }),
  computed: {
    breadcrumb() {
      return [
        {
          title: this.$t('page.cloud'),
          name: 'cloud-index'
        }
      ]
    },
    categoryIndexApi() {
      return this.$api.cloud.public.categoryIndex
    },
    seoTitle() {
      return this.$store.getters['base/cloud_seo_title']
    },
    seoDescription() {
      return this.$store.getters['base/cloud_seo_description']
    },
    seoKeywords() {
      return this.$store.getters['base/cloud_seo_keywords']
    },
  },
  watch: {
    count() {
      if(this.count == 2) {
        this.setupMeta()
      }
    },
  },
  methods: {
    afterIndexFetch() {
      this.count += 1
    },
  },
};

</script>

<style lang="sass" type="text/sass">
.filterResults-index-bg
  background-image: url('~@/assets/img/bg/filterResultBg.png')
  background-position: center top
  -webkit-backdrop-filter: blur(30px)
</style>